import React from 'react';
import styles from './NoFreeBoxesPopup.module.css'
import googlebutton from '../../assets/googlebutton.png'

import checkCircle from '../../assets/check_circle.svg'
import cross from "../../assets/close_button-removebg-preview.png";

const NoFreeBoxesPopup = ({ onSeeOtherBoxes, closePopup }) => {
  setTimeout(()=>{closePopup()},5000)
  return (
    <div className={styles.popup}>
      <div className={styles.popupinner}>
        <div className={styles.popupTop}>
          <div>
            <img src={checkCircle}></img>
          </div>
          <div style={{textAlign:'start'}}>
            <button onClick={closePopup} className={styles.closebutton}> ⓧ </button>
            <p className={styles.messageName}>Get your bonus today</p>
            <p className={styles.message}>Make a deposit now, and we’ll instantly credit your bonus to your account. </p>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center', backgroundColor: '#3E28E8',marginTop:'10px',marginLeft:'10px', borderRadius: '50px',fontSize:'13px', textAlign: 'center',width:'105px',height:'30px', color: 'white' }} 
            onClick={()=>window.location.href='https://buy.stripe.com/fZecPK7C1dmr70kfZ0'}>Add credits</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoFreeBoxesPopup;
