import React from "react";
import styles from "./Main.module.css";
import Banner from "../changeableBanner/Banner";
import localshipping from "../../assets/local_shipping.png";
import steps from "../../assets/steps.png";
import datacheck from "../../assets/data_check.png";
import LiveAnbox from "../LiveAnbox/LiveAnbox";
import Cryptoicons from "../Cryptoicons/Cryptoicons";
import infobtn from "../../assets/Info.svg";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const AllBoxes = ({
  featuredboxes,
  currentBanner,
  onshowmore,
  showLogin,
  switchBanner,
  isLoggedIn,
  showDP,
}) => {
  const howItWorksRef = useRef(null);
  const navigate = useNavigate();

  const openBoxPage = (boxinfo) => {
    navigate(`/box/${boxinfo.id}`);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftbody}>

          <h1 className={styles.fatheader}>All featured luxury boxes</h1>
          <div id={styles.featuredluxuryboxes}>
            {featuredboxes.map((item, i) => (
              <div
                onClick={() => openBoxPage(item)}
                key={i}
                className={styles.box}
              >
                <div className={styles.mainBoxImg}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={window.clientConfig.imageUrl + item.image}
                  ></img>
                  <div className={styles.boxName}>{item.name}</div>
                </div>

                <div className={styles.boxinfo}>
                  <div
                    style={{
                      backgroundColor: "white",
                      width: '100%', borderRadius: "10px"}}
                  >
                    <div
                      style={{
                        backgroundColor: "#FFC658",
                        borderRadius: "50px",
                        padding: "5px",
                        fontWeight: "bold",
                        fontFamily: "Arial",
                        display: "flex",
                        justifyContent: "center",
                        height: "fit-content",
                      }}
                    >
                      {"Open for $"}
                      {item.price}
                    </div>
                  </div>
                </div>
                {/* <img
              style={{ width: "100%", height: "100%" }}
              src={window.clientConfig.imageUrl + item.image}
            ></img> */}
              </div>
            ))}
          </div>
        </div>
        <LiveAnbox />
      </div>

      
      <div className={styles.bottom}>
        <div id={styles.howitworks}>
          <div ref={howItWorksRef} className={styles.fatheader}>How it works</div>
          <div className={styles.hiwinner}>
            <div className={styles.exactlyhowitworks}>
              <div style={{ borderRadius: '50px', border: '2px solid black', height: '36px', aspectRatio: '1', fontSize: '20px', textAlign: 'center' }}>1</div>
              <div className={styles.eehiw}>

                <h2 className={styles.hiwheader}>Sign Up</h2>

                <p className={styles.hiwtext}>
                  Join via Google or sign up with your email to start unboxing awesome mystery boxes on Anboxme.
                </p>
              </div>
            </div>
            <div className={styles.exactlyhowitworks}><div style={{ borderRadius: '50px', border: '2px solid black', height: '36px', aspectRatio: '1', fontSize: '20px', textAlign: 'center' }}>2</div>
              <div className={styles.eehiw}>
                <h2 className={styles.hiwheader}>Top Up Your Account</h2>
                <p className={styles.hiwtext}>
                  Easily top up your balance by clicking the "+" button in the top menu.
                </p>
              </div>
            </div>
            <div className={styles.exactlyhowitworks}><div style={{ borderRadius: '50px', border: '2px solid black', height: '36px', aspectRatio: '1', fontSize: '20px', textAlign: 'center' }}>3</div>
              <div className={styles.eehiw}>
                <h2 className={styles.hiwheader}>Unbox Mystery Boxes</h2>
                <p className={styles.hiwtext}>
                  Pick a box and unbox it with one click. We guarantee the authenticity of all items.
                </p>
              </div>
            </div>
            <div className={styles.exactlyhowitworks}><div style={{ borderRadius: '50px', border: '2px solid black', height: '36px', aspectRatio: '1', fontSize: '20px', textAlign: 'center' }}>4</div>
              <div className={styles.eehiw}>
                <h2 className={styles.hiwheader}>Ship or Swap Items</h2>
                <p className={styles.hiwtext}>
                  Not what you wanted? No problem! Ship it to your home or exchange it for on-site credit and keep exploring.
                </p>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div onClick={isLoggedIn?(showDP):(showLogin)} style={{ backgroundColor: '#3E28E8', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '280px', height: '41px', color: 'white' }}>Start Unboxing</div>
          </div>

          <div className={styles.hiwinner}>
            <div className={styles.hiwbottom}>
              <div style={{ alignItems: 'center' }} className={styles.eehiw}>
                <h2 style={{ alignI: 'center' }} className={styles.hiwheader}>Provably Fair Technology Guarantees 100% Fairness</h2>
                <p style={{ textAlign: 'center' }} className={styles.hiwtext}>
                  Our system ensures full transparency, with no possibility of manipulation in any mystery box draw.
                </p>
              </div>
            </div>
            <div className={styles.hiwbottom}>
              <div style={{ alignItems: 'center' }} className={styles.eehiw}>
                <h2 style={{ textAlign: 'center' }} className={styles.hiwheader}>100% Authentic Items Guaranteed</h2>
                <p style={{ textAlign: 'center' }} className={styles.hiwtext}>
                  Every item from Anboxme is verified for authenticity, so you always get the real deal—no exceptions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cryptoicons />
    </>
  );
};

export default AllBoxes;
