import React, { useState } from 'react';
import styles from './SuccessReq.module.css'
import cross from '../../assets/close_button-removebg-preview.png';
import checkCircle from '../../assets/check_circle.svg'


function SuccessReq({ closePopup, successMessage }) {
  setTimeout(()=>{closePopup()},15000)

  const [messageName,setMessageName]=useState();
  const [message,setMessage]=useState();

  React.useEffect(()=>{
    switch(successMessage){
      case 'withdrawMoney':
        setMessageName('Withdrawal in progress');
        setMessage('Your request has been received. We are processing it now.');
        return;
      case 'withdraw':
        setMessageName('Order created');
        setMessage('Your order is in progress. We’ll reach out shortly.');
        return;
      case 'sell':
        setMessageName('Item sold');
        setMessage('Your balance is now updated!');
        return;
    }
  },[successMessage])

  const buttonclicktest = () => {
    alert('You have just clicked on a button without an assigned action. Please assign an OnClick event to this button.')
  }

  return (
    <div className={styles.popup}>
      <div className={styles.popupinner}>
        <div className={styles.popupTop}>
          <div>
            <img src={checkCircle}></img>
          </div>
          <div style={{textAlign:'start'}}>
            <button onClick={closePopup} className={styles.closebutton}> ⓧ </button>
            <p className={styles.messageName}>{messageName}</p>
            <p className={styles.message}>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessReq;