import React from "react";
import styles from "./Banner.module.css";
import pinkbg from "../../assets/banner1.svg";
import greenbg from "../../assets/banner2.svg";
import yellowbg from "../../assets/banner3.svg";
import newBanner from "../../assets/newBanner.svg";

const banners = [newBanner];
const headers = [
  "",
  "",
  "",
];
const paragraphs = [
  "",
  "",
  "",
];
const alts =["Open online gaming lootbox as hype drop but better. Outlet discount online luxury shopping"]

const Banner = ({ currentBanner, switchBanner }) => (
  <div style={{ display: "grid", overflow: "hidden",position:'relative' }}>
    <div
      style={{
        position: "relative",
        transform: `translateX(-${(((currentBanner - 1) % banners.length) * 100) / banners.length}%)`,
        transition: `transform 1000ms ease`,
        display: "flex",
      }}
    >
      {banners.map((banner, num) => (
        <div
        className={styles.bannercontainer} key={num} style={{ position: "relative",padding:"0px 10px" }}>
          <div
            className={styles.bannerText}
            style={{
              position: "absolute",
              top: "5vw",
              left: "5vw",
              maxWidth: "70%",
            }}
          >
            <h1 className={styles.header}>{headers[num]}</h1>
            <p className={styles.paragraph}>{paragraphs[num]}</p>
          </div>
          <img alt={alts[num]} src={banner} className={styles.banner} />
        </div>
      ))}
    </div>
    <div id={styles.indicatorContainer}> 
      {banners.map((banner, id) => (<div key={id} id={id==(currentBanner-1)%banners.length?(styles.selected):(styles.unselected)} className={styles.indicator}></div>
      ))}
    </div>
  </div>
);

export default Banner;
